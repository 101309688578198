import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useTable from "../../../hooks/useTable";
import AdminListService from "../../../services/admin-list.service";

import ToTop from "../includes/ToTop";
import { useSelector } from "react-redux";

export default function ViewOsrHistroy() {
  const state = useSelector((state) => state.stateVals);

  const { osr_id } = state;

  const [loading, setLoading] = useState(false);

  const [UserData, setUserData] = useState([]);

  const [customerModelView, setCustomerModelView] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [filteredData, setFilteredData] = useState([]);

  const getOsrHistroy = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.getOsrHistroyData();
      let res;
      if (responce.status === 200) {
        if (Array.isArray(responce?.data?.data?.data)) {
          res = responce?.data?.data?.data;
        } else if (typeof responce?.data?.data?.data === "object") {
          res = Object.values(responce.data.data.data);
        }

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            Sitname: value.Sitename,
            submited: value.submited,
            Participants: value.Participants,
            jobNo: value.jobNo,
            bulk: value.bulk,
          });
        });

        setUserData([...results]);
        setFilteredData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setUserData([]);
      setFilteredData([]);

      setLoading(false);
    }
  };
  useEffect(() => {
    if (osr_id) {
      getOsrHistroy();
    }

    // eslint-disable-next-line
  }, []);
  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = UserData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.submited.toString().toLowerCase().includes(searchQuery) ||
          item.Sitname.toString().toLowerCase().includes(searchQuery) ||
          item.Participants.toString().toLowerCase().includes(searchQuery) ||
          item.jobNo.toString().toLowerCase().includes(searchQuery) ||
          item.bulk.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(UserData);
    }
  };

  const viewOsrHistroyDetails = async (gpNo) => {
    setCustomerModelView([]);

    setLoading(true);
    try {
      const responce = await AdminListService.viewOsrHistroyInModel(
        gpNo,
        "1",
        "20"
      );
      console.log(responce);
      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            FirstName: value.FirstName,
            LastName: value.LastName,
            Address: value.Address,
            City: value.City,
            State: value.State,
            ZipCode: value.ZipCode,
            PhoneNumber: value.PhoneNumber,
            Email: value.Email,
            Denomination: value.Denomination,
            CardName: value.CardName,
            JobNumber: value.JobNumber,
          });
        });

        setCustomerModelView([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCustomerModelView([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
    }
  };

  const Table = ({ data }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, 20);
    // const { slice, range } = useTable(data, page, 100);
    return (
      <>
        <div className="table-responsive mt-3">
          <table className="table table-striped table-bordered W-100  align-middle">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Date Submitted </th>
                <th>No. of Customers </th>
                <th>Job No. </th>
                <th>Bulk</th>
                <th width="13%">Action</th>
              </tr>
            </thead>
            <tbody>
              {slice.length ? (
                <>
                  {slice.map((el, index) => (
                    <tr key={index}>
                      <td>{el.Sitname}</td>
                      <td>{el.submited}</td>
                      <td>{el.Participants}</td>
                      <td>{el.jobNo}</td>
                      <td>{el.bulk}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3 fs-6">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-success"
                            data-bs-toggle="modal"
                            data-bs-target="#view_date_model"
                            title="View"
                            onClick={() => {
                              viewOsrHistroyDetails(el.groupNo);
                            }}
                          >
                            <i className="bi bi-eye-fill"></i> View
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="6" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {data.length && data.length > 20 ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={20}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const viewCustomerModelPagination = (gpNo, e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);
      try {
        const responce = await AdminListService.viewOsrHistroyInModel(
          gpNo,
          pageNo
        );

        let res;

        if (responce.status === 200) {
          res = responce.data.data.data;

          const results = [];

          res.map((value) => {
            return results.push({
              groupNo: value.groupNo,
              FirstName: value.FirstName,
              LastName: value.LastName,
              Address: value.Address,
              City: value.City,
              State: value.State,
              ZipCode: value.ZipCode,
              PhoneNumber: value.PhoneNumber,
              Email: value.Email,
              Denomination: value.Denomination,
              CardName: value.CardName,
              JobNumber: value.JobNumber,
            });
          });

          setCustomerModelView([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.data.total_records);
          setTotalPages(responce.data.data.total_pages);
          setCurrentPage(responce.data.data.current_page);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setCustomerModelView([]);
        setTotalResults(0);
        setTotalPages(0);
        setLoading(false);
      }
    };
    getPaginationData();
  };

  const CustomerModelTable = ({ data }) => {
    return (
      <>
        <div className="table-responsive mt-3">
          <table className="table table-striped table-bordered W-100  align-middle">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name </th>
                <th>Address </th>
                <th>City </th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Denomination</th>
                <th>Job Number</th>
                <th>Cards</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                <>
                  {data.map((el, index) => (
                    <tr key={index}>
                      <td>{el.FirstName}</td>
                      <td>{el.LastName}</td>
                      <td>{el.Address}</td>
                      <td>{el.City}</td>
                      <td>{el.State}</td>
                      <td>{el.ZipCode}</td>
                      <td>{el.PhoneNumber}</td>
                      <td>{el.Email}</td>
                      <td>{el.Denomination}</td>
                      <td>{el.JobNumber}</td>

                      <td
                        dangerouslySetInnerHTML={{ __html: el.CardName }}
                      ></td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="11" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              className="justify-content-center"
              onChange={(e) => {
                viewCustomerModelPagination(data[0]?.groupNo, e);
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  return (
    <div className="wrapper">
      <Helmet>
        <title>OSR Dashboard</title>
      </Helmet>
      <main className="page-content customer-contnent">
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-35">
              Welcome, OSR
              {/* {process.env.REACT_APP_SITE_NAME} */}
              <Link
                to="/osr_dashboard"
                className="btn btn-primary d-inline-block float-end "
              >
                Go to Dashboard
              </Link>
              <div className="clearfix"></div>
            </h5>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-9">
                <p className="font-14">
                  <strong>Below are the pending customer data for jobs.</strong>
                </p>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  onChange={handleSearch}
                  placeholder="Search"
                  className="form-control"
                />
              </div>
            </div>

            <Table data={filteredData} />
          </div>
        </div>
      </main>

      <div
        className="modal fade"
        id="view_date_model"
        tabIndex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <CustomerModelTable data={customerModelView} />
              {/* <div className="table-responsive">
                <table
                  id="viewdata"
                  className="table table-striped table-bordered w-100"
                >
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name </th>
                      <th>Address </th>
                      <th>City </th>
                      <th>State</th>
                      <th>Zip Code</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Denomination</th>
                      <th>Job Number</th> <th>Cards</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>NICHOLAS</td>
                      <td>VRANES</td>
                      <td>290 FREEDOM HEIGHTS DR</td>
                      <td>HARTWELL</td>
                      <td>GA</td>
                      <td>30643</td>
                      <td>208-391-1235</td>
                      <td>NVRANES@HOTMAIL.COM</td>
                      <td>100</td>
                      <td>38805</td>
                      <td>Master Card</td>
                    </tr>
                    <tr>
                      <td>PANKAJ</td>
                      <td>KHURANA</td>
                      <td>8740 RADFORD LN</td>
                      <td>SUWANEE</td>
                      <td>GA</td>
                      <td>30024</td>
                      <td>224-209-4025</td>
                      <td>PANKAJAPPLIES@GMAIL.COM</td>
                      <td>100</td>
                      <td>38805</td>
                      <td>Master Card</td>
                    </tr>
                    <tr>
                      <td>PRASHANTH</td>
                      <td>KODURUPATI</td>
                      <td>1614 DEERFIELD PT</td>
                      <td>ALPHARETTA</td>
                      <td>GA</td>
                      <td>30004</td>
                      <td>414-458-1514</td>
                      <td>PRASHANTH.BACHI21@GMAIL.COM</td>
                      <td>100</td>
                      <td>38805</td>
                      <td>Master Card</td>
                    </tr>

                    <tr>
                      <td>COURTNEY</td>
                      <td>MADDOX</td>
                      <td>638 INMAN RD</td>
                      <td>HAMPTON</td>
                      <td>GA</td>
                      <td>30228</td>
                      <td>770-712-0180</td>
                      <td>COURTNEYVMADDOX@YAHOO.COM</td>
                      <td>100</td>
                      <td>38805</td>
                      <td>Master Card</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <ToTop />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

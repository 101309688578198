import React from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

import $ from "jquery";
import AdminListService from "../../../services/admin-list.service";


export default function AddByJob() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [cardData, setCardData] = useState([]);
  let navigate = useNavigate();

  let query = useQuery();

  const state = useSelector((state) => state.stateVals);

  const { admin_id } = state;

  const [loading, setLoading] = useState(false);

  const [tableData, settableData] = useState([]);


  useLayoutEffect(() => {
    const allCards = async () => {
      setLoading(true);
      try {
        const responce = await AdminListService.getAllCard();

        if (responce.status === 200) {
          const res = responce.data.data;
          const results = [];
          res.map((value) => {
            return results.push({
              CardId: value.CardId,
              CardImage: value.CardImage,
              CardName: value.CardName,
            });
          });

          settableData([...results]);

          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        settableData([]);
      }
    };
    allCards();

    const getJobByGroup = async () => {
      setLoading(true);
      try {
        const responce = await AdminListService.getCardByJobGroup(
          query.get("jobId"),
          query.get("gn")
        );

        if (responce.status === 200) {
          setLoading(false);

          const res = responce.data.data.data;
          const cards = [];
          res.map((value) => {
            return cards.push(value.CardId);
          });

          setCardData({ ...cards });
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    };

    getJobByGroup();

    // eslint-disable-next-line
  }, []);

  const addJob = async () => {
    var selectedCards = await $(".on-off-cards input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");
    if (!selectedCards.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Please select card!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    } else {
      setLoading(true);

      try {
     

        const responce = await AdminListService.updateCardByJobGroup(
          query.get("jobId"),
          selectedCards,
          admin_id,
          query.get("gn")
        );

        if (responce.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Added Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });

          setTimeout(() => {
            navigate("/manage_by_job");
          }, 1500);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (
          err?.response?.data?.message &&
          typeof err?.response?.data?.message === "string"
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: err?.response?.data?.message,
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            text: "Some thing went wrong!",
            confirmButtonColor: "#e72e2e",
            confirmButtonText: "Try Again",
          });
        }
      }
    }
  };

  const Cards = ({ data, avalCard }) => {
    const convertedArray = Object.values(avalCard);

    return data.length
      ? data.map((el, index) => (
          <div className="col-12 col-md-4 col-lg-3 text-center" key={index}>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id={el.CardId}
                value={el.CardId}
                defaultChecked={
                  convertedArray?.includes(el.CardId) ? true : false
                }
              />
              <label className="form-check-label" htmlFor={el.CardId}>
                {el.CardName}

                {el.CardImage !== "" ? (
                  <img
                    src={`${process.env.REACT_APP_IMG_LINK}/resources/images/cards/${el.CardImage}`}
                    className="card_logo_img"
                    alt="card pic"
                  />
                ) : (
                  <div className="pirds pirds2">
                    <span className="rdcrdtop">
                      <table border="0">
                        <tbody>
                          <tr>
                            <td>{el.CardName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                    <span className="rdcrd">REWARD CARD</span>
                  </div>
                )}
              </label>
            </div>
          </div>
        ))
      : null;
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Add Job</h5>
            </div>

            <div className="card-body">
              <div className="row g-3">
                <div className="on-off-cards">
                  <div className="row">
                    <Cards data={tableData} avalCard={cardData} />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <button className="btn btn-primary w-100" onClick={addJob}>
                    Save Job Record
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

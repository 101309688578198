import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div id="notfound">
      <Helmet>
        <title>404 Page Not Found</title>
      </Helmet>
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
        </div>
        <h2>We are sorry, Page not found!</h2>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          Back To Homepage
        </button>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import $ from "jquery";
export default function ToTop() {
  const [Dark, setDark] = useState(localStorage.getItem("theme"));

  const setTheme = async (e) => {
    if (e.target.value === "DarkTheme") {
      localStorage.setItem("theme", "DarkTheme");
      setDark("DarkTheme");
    } else {
      localStorage.setItem("theme", "LightTheme");
      setDark("LightTheme");
    }
  };

  useEffect(() => {
    $(window).on("scroll", function () {
      $(this).scrollTop() > 300
        ? $(".back-to-top").fadeIn()
        : $(".back-to-top").fadeOut();
    });
    if (Dark === "DarkTheme") {
      $("html").attr("class", "dark-theme");
    } else {
      $("html").attr("class", "light-theme");
    }
  }, [Dark]);

  const backToTop = () => {
    return (
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        600
      ),
      !1
    );
  };


  
  return (
    <>
      <div className="overlay nav-toggle-icon"></div>

      <div className="back-to-top" onClick={backToTop}>
        <i className="bi bi-arrow-up top-arrow"></i>
      </div>

      <div className="switcher-body">
        <button
          className="btn btn-primary btn-switcher shadow-sm"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasScrolling"
          aria-controls="offcanvasScrolling"
        >
          <i className="bi bi-paint-bucket me-0"></i>
        </button>
        <div
          className="offcanvas offcanvas-end shadow border-start-0 p-2"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabIndex="-1"
          id="offcanvasScrolling"
        >
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
              Theme Customizer
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
            ></button>
          </div>
          <div className="offcanvas-body">
            <h6 className="mb-0">Theme Variation</h6>
            <hr />

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="LightTheme"
                value="LightTheme"
                defaultChecked={Dark === "DarkTheme" ? false : true}
                onClick={(e) => {
                  setTheme(e);
                }}
              />
              <label className="form-check-label" htmlFor="LightTheme">
                Light
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="DarkTheme"
                value="DarkTheme"
                defaultChecked={Dark === "DarkTheme" ? true : false}
                onClick={(e) => {
                  setTheme(e);
                }}
              />
              <label className="form-check-label" htmlFor="DarkTheme">
                Dark
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const logIn = (states, type) => {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: states,
    });
  };
};

export const logOut = (type) => {
  return (dispatch) => {
    dispatch({
      type: type,
    });
  };
};

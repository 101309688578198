import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";
import { useSelector } from "react-redux";

export default function ManageByJob() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [limit, setLimit] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const cardList = async (page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.listCardByJob(page);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];

        res.map((value) => {
          return results.push({
            card:
              value.card !== ""
                ? value.card.map((item) => {
                    return { name: item };
                  })
                : [{ name: "" }],
            group: value.group,
            customer: value.customer,
            certificate: value.certificate,

            job: value.job,
            jobId: value.jobId,
          });
        });

        settableData([...results]);
        setFilteredData([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      cardList("1");
    }
  }, [adminAccessToken]);
  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.customer.toString().toLowerCase().includes(searchQuery) ||
          item.certificate.toString().toLowerCase().includes(searchQuery) ||
          item.job.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths align-middle">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("job")}
                className={getClassNamesFor("job")}
              >
                Job Number
              </th>
              <th
                scope="col"
                onClick={() => requestSort("card")}
                className={getClassNamesFor("card")}
              >
                Job Cards
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customer")}
                className={getClassNamesFor("customer")}
              >
                Company Name
              </th>

              <th
                scope="col"
                onClick={() => requestSort("certificate")}
                className={getClassNamesFor("certificate")}
              >
                Certificates
              </th>

              <th
                scope="col"
                onClick={() => requestSort("jobId")}
                className={getClassNamesFor("jobId")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{el.job}</td>
                  <td>
                    <div className="img-card-multi">
                      {el.card.map((el, index) =>
                        el.name.startsWith("http") ? (
                          <img
                            src={el.name}
                            alt="card logo"
                            className="card-logo-img"
                            key={index}
                          />
                        ) : el.name !== "" ? (
                          <div className="pirds pirds2" key={index}>
                            <span className="rdcrdtop">
                              <table border="0">
                                <tbody>
                                  <tr>
                                    <td>{el.name}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </span>
                            <span className="rdcrd">REWARD CARD</span>
                          </div>
                        ) : (
                          "N/A"
                        )
                      )}
                    </div>
                  </td>
                  <td>{el.customer}</td>
                  <td>
                    <div className="certi-logn">{el.certificate}</div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <Link
                        className="btn btn-sm btn-primary"
                        title=""
                        to={`/job_add_by_job?gn=${el.group}&jobId=${el.jobId}`}
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              cardList(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Manage Job</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-9"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>
              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

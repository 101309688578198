import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  cardForExistJob,
  cardForJob,
  customerAdd,
  jobNumber,
  redemptionDateForExistJob,
} from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import ToTop from "../includes/ToTop";
import $ from "jquery";
import moment from "moment/moment";
import { Helmet } from "react-helmet";

import useTable from "../../../hooks/useTable";
import Select from "react-select";

export default function OsrIndex() {
  const state = useSelector((state) => state.stateVals);

  const { osr_id } = state;

  const [loading, setLoading] = useState(false);

  const [UserData, setUserData] = useState([]);
  const [customerModelView, setCustomerModelView] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [StatesList, setStatesList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [oldUploadCards, setOldUploadCards] = useState([]);

  const [EmailChecking, setEmailChecking] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [EditId, setEditId] = useState("");
  const [gropNo, setgropNo] = useState("");
  const [siteId, setSiteId] = useState("");

  const getOsr = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.getOsrData();

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,
            Sitname: value.Sitename,
            submited: value.submitted,
            Participants: value.Participants,
            jobNo: value.jobNo,
            bulk: value.bulk,
            SiteId: value.SiteId,
          });
        });

        setUserData([...results]);
        setFilteredData([...results]);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setUserData([]);
      setFilteredData([]);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (osr_id) {
      getOsr();
    }

    // eslint-disable-next-line
  }, []);

  const viewCustomerModel = async (gpNo) => {
    setCustomerModelView([]);
    setTotalPages(0);
    setLoading(true);
    try {
      const responce = await AdminListService.viewCustomerInModel(gpNo, "1");

      let res;

      if (responce.status === 200) {
        res = responce.data.data.data;

        const results = [];

        res.map((value) => {
          return results.push({
            groupNo: value.groupNo,

            FirstName: value.FirstName,
            LastName: value.LastName,
            Address: value.Address,
            City: value.City,
            State: value.State,
            ZipCode: value.ZipCode,
            PhoneNumber: value.PhoneNumber,
            Email: value.Email,
            Denomination: value.Denomination,
            UserId: value.UserId,
          });
        });

        setCustomerModelView([...results]);
        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setCustomerModelView([]);
      setTotalResults(0);
      setTotalPages(0);
      setLoading(false);
    }
  };

  const viewCustomerModelPagination = (gpNo, e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);
      try {
        const responce = await AdminListService.viewCustomerInModel(
          gpNo,
          pageNo
        );

        let res;

        if (responce.status === 200) {
          res = responce.data.data.data;

          const results = [];

          res.map((value) => {
            return results.push({
              groupNo: value.groupNo,
              FirstName: value.FirstName,
              LastName: value.LastName,
              Address: value.Address,
              City: value.City,
              State: value.State,
              ZipCode: value.ZipCode,
              PhoneNumber: value.PhoneNumber,
              Email: value.Email,
              Denomination: value.Denomination,
              UserId: value.UserId,
            });
          });

          setCustomerModelView([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.data.total_records);
          setTotalPages(responce.data.data.total_pages);
          setCurrentPage(responce.data.data.current_page);

          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setCustomerModelView([]);
        setTotalResults(0);
        setTotalPages(0);
        setLoading(false);
      }
    };
    getPaginationData();
  };

  const deleteOsr = async (groupId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          const response = await AdminListService.deleteOsrMain(groupId);
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              window.location.reload();
              // getOsr();
            }, 1500);
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    });
  };

  const deleteModelCustomer = async (cusId, gpNo) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          const response = await AdminListService.deleteSingleOsrCustomer(
            cusId
          );
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              getOsr();
              viewCustomerModel(gpNo);
            }, 1500);
          }
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
          setLoading(false);
        }
      }
    });
  };

  const submitJob = async (grpNo, jobNo) => {
    try {
      setLoading(true);

      const response = await AdminListService.jobSubmit(grpNo, osr_id, jobNo);

      if (response.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Job number submitted successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          window.location.reload();
          // getOsr();
        }, 1500);
      }
    } catch (err) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Some thing went wrong!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = UserData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.submited.toString().toLowerCase().includes(searchQuery) ||
          item.Sitname.toString().toLowerCase().includes(searchQuery) ||
          item.Participants.toString().toLowerCase().includes(searchQuery) ||
          item.jobNo.toString().toLowerCase().includes(searchQuery) ||
          item.bulk.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(UserData);
    }
  };

  const Table = ({ data }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, 20);
    return (
      <>
        <div className="table-responsive mt-3">
          <table className="table table-striped table-bordered W-100  align-middle">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Date Submitted </th>
                <th>No. of Customers </th>
                <th>Job No. </th>
                <th>Bulk</th>
                <th width="30%">Action</th>
              </tr>
            </thead>
            <tbody>
              {slice.length ? (
                <>
                  {slice.map((el, index) => (
                    <tr key={index}>
                      <td>{el.Sitname}</td>
                      <td>{el.submited}</td>
                      <td>{el.Participants}</td>
                      <td className="text-uppercase">{el.jobNo}</td>
                      <td>{el.bulk}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3 fs-6">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-success"
                            data-bs-toggle="modal"
                            data-bs-target="#view_date_model"
                            title="View"
                            onClick={() => {
                              setStatesList([]);
                              viewCustomerModel(el.groupNo);
                            }}
                          >
                            <i className="bi bi-eye-fill"></i> View
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            title="Delete"
                            onClick={() => {
                              deleteOsr(el.groupNo);
                            }}
                          >
                            <i className="bi bi-trash-fill"></i> Delete
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            data-bs-toggle="modal"
                            data-bs-target="#add_job_number"
                            title="Add Job Number"
                            onClick={() => {
                              setgropNo(el.groupNo);
                              setSiteId(el.SiteId);
                              setAdminCards([]);
                            }}
                          >
                            <i className="bi bi-plus-lg"></i> Add Job Number
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary"
                            title="Submit Data"
                            disabled={el.jobNo === "n/a" ? true : false}
                            onClick={() => {
                              submitJob(el.groupNo, el.jobNo);
                            }}
                          >
                            <i className="bi bi-upload"></i> Submit Data
                          </button>
                        </div>
                      </td>

                      {/* <td>
                        <div className="d-flex align-items-center gap-3 fs-6">
                          <Link
                            to="/upload_osr"
                            className=" text-success"
                            title="Upload"
                          >
                            <i className="bi bi-file-earmark-pdf"></i> Upload
                            File
                          </Link>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="6" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {data.length && data.length > 20 ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={20}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  // customer in osr

  const CustomerModelTable = ({ data }) => {
    return (
      <>
        <div className="table-responsive mt-3">
          <table className="table table-striped table-bordered W-100  align-middle">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name </th>
                <th>Address </th>
                <th>City </th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Denomination</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                <>
                  {data.map((el, index) => (
                    <tr key={index}>
                      <td>{el.FirstName}</td>
                      <td>{el.LastName}</td>
                      <td>{el.Address}</td>
                      <td>{el.City}</td>
                      <td>{el.State}</td>
                      <td>{el.ZipCode}</td>
                      <td>{el.PhoneNumber}</td>
                      <td>{el.Email}</td>
                      <td>{el.Denomination}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3 fs-6">
                          <button
                            className="text-warning open-model-btn "
                            data-bs-toggle="modal"
                            data-bs-target="#edit_customer_model"
                            title=""
                            onClick={() => {
                              setEditId(el.UserId);

                              editOsrCustomer(el.UserId);
                            }}
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </button>
                          <button
                            type="button"
                            className="text-danger open-model-btn"
                            onClick={() => {
                              deleteModelCustomer(el.UserId, el.groupNo);
                            }}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="10" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              className="justify-content-center"
              onChange={(e) => {
                viewCustomerModelPagination(data[0]?.groupNo, e);
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const editOsrCustomer = async (cusId) => {
    setLoading(true);
    try {
      const response = await AdminListService.getOsrCustomerData(cusId);

      if (response.status === 200) {
        setLoading(false);
        getStatesNames();

        osrCustomerEdit.setValues({
          groupNo: response.data.data.groupNo,
          fname: response.data.data.FirstName,
          lname: response.data.data.LastName,
          address: response.data.data.Address,
          city: response.data.data.City,
          state: response.data.data.State,
          zip: response.data.data.ZipCode,
          email: response.data.data.Email,
          phone: response.data.data.PhoneNumber
            ? response.data.data.PhoneNumber
            : "",
          denomination: response.data.data.Denomination,
        });
        setEmailError("");
        setEmailChecking(false);
      }
    } catch (err) {
      console.log(err);

      setLoading(false);
    }
  };

  const varifyEmail = async (e) => {
    setEmailError("");
    if (e.target.value !== "") {
      setEmailChecking(true);
      let response;
      try {
        response = await AdminListService.checkOsrCustomerEmail(
          e.target.value,
          EditId
        );

        if (response.status === 200) {
          setEmailError("");
          setEmailChecking(false);
        }
      } catch (err) {
        if (err.response?.status === 409) {
          setEmailError("Email already exists");
          setEmailChecking(false);
        }
      }
    }
  };

  const getStatesNames = async () => {
    try {
      const responce = await AdminListService.getStates();

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          value: value.code,
          label: value.state_name,
        });
      });
      setStatesList([...results]);
    } catch (err) {
      console.log(err);
      setStatesList([]);
    }
  };

  const UpdateOsrCustomer = async (values, action) => {
    if (emailError === "") {
      try {
        setLoading(true);

        const response = await AdminListService.updateCustomerOsr(
          values,
          osr_id,
          EditId
        );

        if (response.status === 200) {
          setLoading(false);
          action.resetForm();

          Swal.fire({
            icon: "success",
            title: "Updated Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          $("#editCustomerModelBtn").trigger(clickEvent);
          await setStatesList([]);
          setTimeout(() => {
            viewCustomerModel(values.groupNo);
          }, 1500);
        }
      } catch (err) {
        setLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const osrCustomerEdit = useFormik({
    initialValues: {
      groupNo: "",
      fname: "",
      lname: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      phone: "",
      denomination: "",
    },
    validationSchema: customerAdd,
    onSubmit: (values, action) => {
      if (emailError === "") {
        UpdateOsrCustomer(values, action);
      }
    },
  });
  var clickEvent = $.Event("click");
  // job number add in osr
  const [jobNumberValue, setjobNumberValue] = useState("");
  const varifyJobNumber = async (values, action) => {
    setLoading(true);
    await setjobNumberValue(values.jobNo);
    try {
      const response = await AdminListService.checkJobNumber(values.jobNo);

      if (response.status === 200) {
        setLoading(false);
        action.resetForm();
        getCardsAdmin();
        getLastUploadedCards();

        // $("#newJob").trigger(clickEvent);
        // $("#oldCards").trigger(clickEvent);
      }
    } catch (err) {
      setLoading(false);
      if (err.response?.status === 409) {
        action.resetForm();
        $("#existJobDate").trigger(clickEvent);
      }
    }
  };

  const getLastUploadedCards = async () => {
    setLoading(true);

    try {
      const response = await AdminListService.showLastUploadCards(siteId);

      if (response.status === 200) {
        setLoading(false);

        const res = response.data.data;
        const results = [];
        await res.map((value) => {
          return results.push({
            CardImage: value,
          });
        });
        await setOldUploadCards([...results]);
        $("#oldCards").trigger(clickEvent);
      }
    } catch (err) {
      setLoading(false);
      if (err.response?.status === 404) {
        $("#newJob").trigger(clickEvent);
        await setOldUploadCards([]);
      }
    }
  };

  // const saveLastUpload = async () => {
  //   try {
  //     setLoading(true);

  //     const response = await AdminListService.lastUploadSave(
  //       gropNo,
  //       osr_id,
  //       jobNumberValue,
  //       siteId
  //     );

  //     if (response.status === 200) {
  //       setLoading(false);

  //       $("#oldJob").trigger(clickEvent);
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     if (err?.response?.status === 400) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         iconColor: "#e04f5d",
  //         text: "Certificate already exist",
  //         confirmButtonColor: "#e72e2e",
  //         confirmButtonText: "Try Again",
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         iconColor: "#e04f5d",
  //         text: "Some thing went wrong!",
  //         confirmButtonColor: "#e72e2e",
  //         confirmButtonText: "Try Again",
  //       });
  //     }
  //   }
  // };

  const jobNumberForm = useFormik({
    initialValues: {
      jobNo: "",
      paNo: "",
    },
    validationSchema: jobNumber,
    onSubmit: (values, action) => {
      varifyJobNumber(values, action);
    },
  });

  // card select  in osr

  const [adminCards, setAdminCards] = useState([]);

  const getCardsAdmin = async () => {
    try {
      const responce = await AdminListService.getAdminCards();

      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        return results.push({
          value: value.CardId,
          label: value.CardName,
        });
      });
      await setAdminCards([...results]);
    } catch (err) {
      console.log(err);
      setAdminCards([]);
    }
  };

  const addFinalJobNo = async (values, action) => {
    try {
      setLoading(true);

      const response = await AdminListService.finalAddJob(
        values,
        gropNo,
        osr_id,
        jobNumberValue
      );

      if (response.status === 200) {
        setLoading(false);
        action.resetForm();

        Swal.fire({
          icon: "success",
          title: "Job number added successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        $("#emailQues").trigger(clickEvent);
      }
    } catch (err) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Some thing went wrong!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };

  const changeStartDate = async (e) => {
    var date = new Date(e.target.value);
    const sixMonthsLater = new Date(date.getTime());
    sixMonthsLater.setMonth(date.getMonth() + 6);
    const formattedEndDate = sixMonthsLater
      .toLocaleDateString("en-US")
      .split("/")
      .join("/");
    cardSelect.setFieldValue(
      "redEdate",
      moment(formattedEndDate).format("YYYY-MM-DD")
    );
  };

  const jobEmailSent = async (status) => {
    try {
      setLoading(true);

      const response = await AdminListService.mailledElectronically(
        gropNo,
        status
      );

      if (response.status === 200) {
        setLoading(false);

        Swal.fire({
          icon: "success",
          title: "Email status updated successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          getOsr();
        }, 1500);
      }
    } catch (err) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        text: "Some thing went wrong!",
        confirmButtonColor: "#e72e2e",
        confirmButtonText: "Try Again",
      });
    }
  };
  const cardSelectInitialValues = {
    card1: "",
    card2: "Select Card 2",
    card3: "Select Card 3",
    card4: "Select Card 4",
    card5: "Select Card 5",
    card6: "Select Card 6",
    card7: "Select Card 7",
    card8: "Select Card 8",
    card9: "Select Card 9",
    card10: "Select Card 10",
    card11: "Select Card 11",
    card12: "Select Card 12",
    redSdate: "",
    redEdate: "",
  };
  const cardSelect = useFormik({
    initialValues: cardSelectInitialValues,
    validationSchema: cardForJob,
    onSubmit: (values, action) => {
      addFinalJobNo(values, action);
    },
  });

  // exist job date add in osr

  const changeExistRedemptionStartDate = async (e) => {
    var date = new Date(e.target.value);
    const sixMonthsLater = new Date(date.getTime());
    sixMonthsLater.setMonth(date.getMonth() + 6);
    const formattedEndDate = sixMonthsLater
      .toLocaleDateString("en-US")
      .split("/")
      .join("/");
    existDateAdd.setFieldValue(
      "redEdate",
      moment(formattedEndDate).format("YYYY-MM-DD")
    );
  };

  const addRedemptionDateForExistingJob = async (values, action) => {
    try {
      setLoading(true);

      const response = await AdminListService.addDateForExistJob(
        values,
        gropNo,
        osr_id,
        jobNumberValue
      );

      if (response.status === 200) {
        setLoading(false);
        action.resetForm();

        $("#existredemptiondatemodelClose").trigger(clickEvent);
        getCardsAdmin();
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Certificate already exist",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const existDateAdd = useFormik({
    initialValues: {
      redSdate: "",
      redEdate: "",
    },
    validationSchema: redemptionDateForExistJob,
    onSubmit: (values, action) => {
      addRedemptionDateForExistingJob(values, action);
    },
  });

  const addRedemptionDateForELastUpload = async (values, action) => {
    try {
      setLoading(true);

      const response = await AdminListService.lastUploadSave(
        gropNo,
        osr_id,
        jobNumberValue,
        siteId,
        values
      );

      if (response.status === 200) {
        setLoading(false);
        action.resetForm();
        Swal.fire({
          icon: "success",
          title: "Job number added successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        $("#emailQues").trigger(clickEvent);

        // $("#existredemptiondatemodelClose").trigger(clickEvent);
        // getCardsAdmin();
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Certificate already exist",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const changeLastUploadRedemptionStartDate = async (e) => {
    var date = new Date(e.target.value);
    const sixMonthsLater = new Date(date.getTime());
    sixMonthsLater.setMonth(date.getMonth() + 6);
    const formattedEndDate = sixMonthsLater
      .toLocaleDateString("en-US")
      .split("/")
      .join("/");
    lastUploadDateAdd.setFieldValue(
      "redEdate",
      moment(formattedEndDate).format("YYYY-MM-DD")
    );
  };

  const lastUploadDateAdd = useFormik({
    initialValues: {
      redSdate: "",
      redEdate: "",
    },
    validationSchema: redemptionDateForExistJob,
    onSubmit: (values, action) => {
      addRedemptionDateForELastUpload(values, action);
    },
  });

  const retainOldJob = async () => {
    try {
      setLoading(true);

      const response = await AdminListService.existJobRetain(
        gropNo,
        osr_id,
        jobNumberValue
      );

      if (response.status === 200) {
        setLoading(false);
        getOsr();
      }
    } catch (err) {
      setLoading(false);

      if (err?.response?.status === 404) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Card Not found!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Select Card",
          showCancelButton: false,
        });
        $("#add_job_number_by_selecy_card_if_no_card").trigger(clickEvent);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  // add new card for existing job
  const addCardForExistJob = async (values, action) => {
    try {
      setLoading(true);

      const response = await AdminListService.cardAddForExistJob(
        values,
        gropNo,
        osr_id,
        jobNumberValue
      );

      if (response.status === 200) {
        setLoading(false);
        action.resetForm();

        Swal.fire({
          icon: "success",
          title: "Job number added successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        $("#add_job_number_by_selecy_card_new_close").trigger(clickEvent);

        setTimeout(() => {
          getOsr();
        }, 1500);
      }
    } catch (err) {
      setLoading(false);

      if (err?.response?.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Certificate already exist",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const cardSelectExistInitialValues = {
    card1: "",
    card2: "Select Card 2",
    card3: "Select Card 3",
    card4: "Select Card 4",
    card5: "Select Card 5",
    card6: "Select Card 6",
    card7: "Select Card 7",
    card8: "Select Card 8",
    card9: "Select Card 9",
    card10: "Select Card 10",
    card11: "Select Card 11",
    card12: "Select Card 12",
  };

  const cardSelectExist = useFormik({
    initialValues: cardSelectExistInitialValues,
    validationSchema: cardForExistJob,
    onSubmit: (values, action) => {
      addCardForExistJob(values, action);
    },
  });

  return (
    <div className="wrapper">
      <Helmet>
        <title>OSR Dashboard</title>
      </Helmet>
      <main className="page-content customer-contnent">
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-35">
              Welcome, OSR
              <Link
                to="/view_osr_history"
                className="btn btn-primary d-inline-block float-end "
              >
                View History
              </Link>
              <div className="clearfix"></div>
            </h5>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-md-9">
                <p className="font-14">
                  <strong>
                    {" "}
                    Below are pending uploads waiting for card selection and job
                    number.
                  </strong>
                </p>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  onChange={handleSearch}
                  placeholder="Search"
                  className="form-control"
                />
              </div>
            </div>

            <Table data={filteredData} />
          </div>
        </div>
      </main>

      {/* view customer model  */}
      <div
        className="modal fade"
        id="view_date_model"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <CustomerModelTable data={customerModelView} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="edit_customer_model"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="card-body">
              <div className="card border shadow-none w-100">
                <div className="card-body">
                  <div className="modal-header border-0 p-0 pb-2">
                    <h4 className="modal-title">Update Customer</h4>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-12 d-flex">
                      <form
                        className="row g-3"
                        onSubmit={osrCustomerEdit.handleSubmit}
                        noValidate
                      >
                        <div className="col-12">
                          <span>
                            <strong>
                              Please enter the information below, if you want to
                              add a customer one by one. Field(s) with * are
                              mandatory.
                            </strong>
                          </span>
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            First Name{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="fname"
                            value={osrCustomerEdit.values.fname || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.fname &&
                          osrCustomerEdit.touched.fname ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.fname}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Last Name <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="lname"
                            value={osrCustomerEdit.values.lname || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.lname &&
                          osrCustomerEdit.touched.lname ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.lname}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Address <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            name="address"
                            value={osrCustomerEdit.values.address || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.address &&
                          osrCustomerEdit.touched.address ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.address}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            City <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            value={osrCustomerEdit.values.city || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.city &&
                          osrCustomerEdit.touched.city ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.city}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            State <strong className="text-danger">*</strong>
                          </label>

                          {StatesList.length ? (
                            <Select
                              className="form-label"
                              placeholder={
                                osrCustomerEdit.values.state !== ""
                                  ? osrCustomerEdit.values.state
                                  : "Select State"
                              }
                              options={StatesList}
                              isSearchable={true}
                              isClearable={true}
                              name="state"
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  osrCustomerEdit.setFieldValue(
                                    "state",
                                    selectedOption.value
                                  );
                                } else {
                                  osrCustomerEdit.setFieldValue("state", "");
                                }
                              }}
                              onBlur={() =>
                                osrCustomerEdit.setFieldTouched("state", true)
                              }
                            />
                          ) : null}

                          {osrCustomerEdit.errors.state &&
                          osrCustomerEdit.touched.state ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.state}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Zip Code <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip Code"
                            name="zip"
                            value={osrCustomerEdit.values.zip || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.zip &&
                          osrCustomerEdit.touched.zip ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.zip}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">Phone Number </label>
                          <input
                            type="string"
                            className="form-control"
                            placeholder="Phone Number"
                            name="phone"
                            value={osrCustomerEdit.values.phone || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.phone &&
                          osrCustomerEdit.touched.phone ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.phone}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Email <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={osrCustomerEdit.values.email || ""}
                            onChange={(e) => {
                              osrCustomerEdit.handleChange(e);
                              varifyEmail(e);
                            }}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.email &&
                          osrCustomerEdit.touched.email ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.email}
                            </span>
                          ) : emailError !== "" ? (
                            <span className="text-danger">{emailError}</span>
                          ) : null}
                        </div>
                        <div className="col-12 col-md-4">
                          <label className="form-label">
                            Denomination{" "}
                            <strong className="text-danger">*</strong>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Denomination"
                            name="denomination"
                            value={osrCustomerEdit.values.denomination || ""}
                            onChange={osrCustomerEdit.handleChange}
                            onBlur={osrCustomerEdit.handleBlur}
                          />
                          {osrCustomerEdit.errors.denomination &&
                          osrCustomerEdit.touched.denomination ? (
                            <span className="text-danger">
                              {osrCustomerEdit.errors.denomination}
                            </span>
                          ) : null}
                        </div>
                        <div className="col-12 text-end">
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="d-flex h-100 w-100 align-items-end mb-mob">
                                <button
                                  className="btn btn-primary d-none"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view_date_model"
                                  data-bs-dismiss="modal"
                                  id="editCustomerModelBtn"
                                ></button>
                                <button
                                  className="btn btn-primary w-100"
                                  disabled={EmailChecking}
                                  type="submit"
                                >
                                  {EmailChecking ? (
                                    <div
                                      className="spinner-border text-light"
                                      role="status"
                                    ></div>
                                  ) : null}
                                  Submit Data
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="add_job_number"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Job Number</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row g-3" onSubmit={jobNumberForm.handleSubmit}>
                <div className="col-12 col-md-4 ">
                  <label className="form-label">Enter Your Job Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Your Job Number"
                    name="jobNo"
                    value={jobNumberForm.values.jobNo || ""}
                    onChange={jobNumberForm.handleChange}
                    onBlur={jobNumberForm.handleBlur}
                  />
                  {jobNumberForm.errors.jobNo && jobNumberForm.touched.jobNo ? (
                    <span className="text-danger">
                      {jobNumberForm.errors.jobNo}
                    </span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4">
                  <label className="form-label">Enter Your PA Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your PA Number"
                    name="paNo"
                    value={jobNumberForm.values.paNo || ""}
                    onChange={jobNumberForm.handleChange}
                    onBlur={jobNumberForm.handleBlur}
                  />
                </div>
                <div className="col-12 col-md-4 mt-md-3 pt-1">
                  <button
                    className="btn btn-primary w-100 mt-md-4"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div className="">
                <button
                  className="btn btn-primary w-100 d-none"
                  type="button"
                  id="oldCards"
                  data-bs-toggle="modal"
                  data-bs-target="#show_old_card"
                ></button>
                <button
                  className="btn btn-primary w-100 d-none"
                  type="button"
                  id="existJobDate"
                  data-bs-toggle="modal"
                  data-bs-target="#add_job_number_by_exist_number"
                ></button>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="add_job_number_by_selecy_card"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Job Number</h5>
            </div>
            <div className="modal-body">
              <div className="alert border-0 border-info border-start border-4 bg-light-info alert-dismissible fade show py-2">
                <div className="d-flex align-items-center">
                  <div className="fs-3 text-info">
                    <i className="bi bi-info-circle-fill"></i>
                  </div>
                  <div className="ms-3">
                    <div className="text-info text-info-bold">
                      {" "}
                      Please select cards for this job{" "}
                    </div>
                  </div>
                </div>
              </div>
              <form onSubmit={cardSelect.handleSubmit} noValidate>
                <div className="row g-3">
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 1</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 1"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card1"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card1",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card1",
                            cardSelectInitialValues.card1
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card1", true)}
                    />
                    {cardSelect.errors.card1 && cardSelect.touched.card1 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card1}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 2</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 2"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card2"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card2",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card2",
                            cardSelectInitialValues.card2
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card2", true)}
                    />
                    {cardSelect.errors.card2 && cardSelect.touched.card2 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card2}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 3</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 3"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card3"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card3",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card3",
                            cardSelectInitialValues.card3
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card3", true)}
                    />
                    {cardSelect.errors.card3 && cardSelect.touched.card3 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card3}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 4</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 4"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card4"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card4",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card4",
                            cardSelectInitialValues.card4
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card4", true)}
                    />
                    {cardSelect.errors.card4 && cardSelect.touched.card4 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card4}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 5</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 5"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card5"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card5",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card5",
                            cardSelectInitialValues.card5
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card5", true)}
                    />
                    {cardSelect.errors.card5 && cardSelect.touched.card5 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card5}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 6</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 6"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card6"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card6",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card6",
                            cardSelectInitialValues.card6
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card6", true)}
                    />
                    {cardSelect.errors.card6 && cardSelect.touched.card6 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card6}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 7</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 7"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card7"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card7",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card7",
                            cardSelectInitialValues.card7
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card7", true)}
                    />
                    {cardSelect.errors.card7 && cardSelect.touched.card7 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card7}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 8</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 8"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card8"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card8",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card8",
                            cardSelectInitialValues.card8
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card8", true)}
                    />
                    {cardSelect.errors.card8 && cardSelect.touched.card8 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card8}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 9</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 9"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card9"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card9",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card9",
                            cardSelectInitialValues.card9
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card9", true)}
                    />
                    {cardSelect.errors.card9 && cardSelect.touched.card9 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card9}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 10</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 10"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card10"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card10",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card10",
                            cardSelectInitialValues.card10
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card10", true)}
                    />
                    {cardSelect.errors.card10 && cardSelect.touched.card10 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card10}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 11</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 11"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card11"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card11",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card11",
                            cardSelectInitialValues.card11
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card11", true)}
                    />
                    {cardSelect.errors.card11 && cardSelect.touched.card11 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card11}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 12</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 12"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card12"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelect.setFieldValue(
                            "card12",
                            selectedOption.value
                          );
                        } else {
                          cardSelect.setFieldValue(
                            "card12",
                            cardSelectInitialValues.card12
                          );
                        }
                      }}
                      onBlur={() => cardSelect.setFieldTouched("card12", true)}
                    />
                    {cardSelect.errors.card12 && cardSelect.touched.card12 ? (
                      <span className="text-danger">
                        {cardSelect.errors.card12}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Redemption Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="redSdate"
                      value={cardSelect.values.redSdate || ""}
                      onChange={(e) => {
                        cardSelect.handleChange(e);
                        changeStartDate(e);
                      }}
                      onBlur={cardSelect.handleBlur}
                    />
                    {cardSelect.errors.redSdate &&
                    cardSelect.touched.redSdate ? (
                      <span className="text-danger">
                        {cardSelect.errors.redSdate}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Redemption End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="redEdate"
                      value={cardSelect.values.redEdate || ""}
                      onChange={cardSelect.handleChange}
                      onBlur={cardSelect.handleBlur}
                      disabled={true}
                    />
                    {cardSelect.errors.redEdate &&
                    cardSelect.touched.redEdate ? (
                      <span className="text-danger">
                        {cardSelect.errors.redEdate}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="row g-3">
                  <div className="col-12 col-md-4">
                    <button
                      className="btn btn-primary w-100 mt-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <button
                className="btn btn-primary w-100 d-none"
                type="button"
                id="emailQues"
                data-bs-toggle="modal"
                data-bs-target="#emailled_electronically"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="emailled_electronically"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Do you want to send</h5>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
            </div>
            <div className="modal-body">
              <h5 className="m-0">You want to mailed electronically.</h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-toggle="modal"
                data-bs-target="#againMailConfirmation"
                data-bs-dismiss="modal"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  jobEmailSent("0");
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade "
        id="againMailConfirmation"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Are you sure want to send</h5>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
            </div>
            <div className="modal-body">
              <h5 className="m-0">
                Are you sure? You want to mailed electronically.
              </h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  jobEmailSent("1");
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  jobEmailSent("0");
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="show_old_card"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Last Upload</h5>
            </div>
            <div className="modal-body">
              <div className="alert  mb-0 border-0 border-info border-start border-4 bg-light-info alert-dismissible fade show py-2">
                <div className="d-flex align-items-center">
                  <div className="fs-3 text-info">
                    <i className="bi bi-info-circle-fill"></i>
                  </div>
                  <div className="ms-3">
                    <div className="text-info text-info-bold">
                      {" "}
                      Would you like to use same card as last upload
                    </div>
                  </div>
                </div>
              </div>
              <br />

              {oldUploadCards.length
                ? oldUploadCards.map((el, index) => (
                    <div className="pirds pirds2 me-2" key={index}>
                      <span className="rdcrdtop">
                        <table border="0">
                          <tbody>
                            <tr>
                              <td>{el.CardImage}</td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                      <span className="rdcrd">REWARD CARD</span>
                    </div>
                  ))
                : "No record found"}
            </div>
            <div className="modal-footer">
              {/* <button
                  className="btn btn-primary"
                  type="button"
                  onClick={saveLastUpload}
                >
                  Yes
                </button> */}
              <button
                className="btn btn-primary"
                type="button"
                id="oldJob"
                data-bs-toggle="modal"
                data-bs-target="#add_job_number_by_saved_number"
              >
                Yes
              </button>
              <button
                className="btn btn-danger"
                type="button"
                id="newJob"
                data-bs-toggle="modal"
                data-bs-target="#add_job_number_by_selecy_card"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="add_job_number_by_saved_number"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Job Number</h5>
            </div>
            <div className="modal-body">
              <div className="alert border-0 border-info border-start border-4 bg-light-info alert-dismissible fade show py-2">
                <div className="d-flex align-items-center">
                  <div className="fs-3 text-info">
                    <i className="bi bi-info-circle-fill"></i>
                  </div>
                  <div className="ms-3">
                    <div className="text-info text-info-bold">
                      {" "}
                      Please Select Redemption Start and End Date{" "}
                    </div>
                  </div>
                </div>
              </div>

              <form
                onSubmit={lastUploadDateAdd.handleSubmit}
                noValidate
                className="row g-3"
              >
                <div className="col-12 col-md-4 ">
                  <label className="form-label">Redemption Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="redSdate"
                    value={lastUploadDateAdd.values.redSdate || ""}
                    onChange={(e) => {
                      lastUploadDateAdd.handleChange(e);
                      changeLastUploadRedemptionStartDate(e);
                    }}
                    onBlur={lastUploadDateAdd.handleBlur}
                  />
                  {lastUploadDateAdd.errors.redSdate &&
                  lastUploadDateAdd.touched.redSdate ? (
                    <span className="text-danger">
                      {lastUploadDateAdd.errors.redSdate}
                    </span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4 ">
                  <label className="form-label">Redemption End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="redEdate"
                    value={lastUploadDateAdd.values.redEdate || ""}
                    onChange={lastUploadDateAdd.handleChange}
                    onBlur={lastUploadDateAdd.handleBlur}
                    disabled={true}
                  />
                  {lastUploadDateAdd.errors.redEdate &&
                  lastUploadDateAdd.touched.redEdate ? (
                    <span className="text-danger">
                      {lastUploadDateAdd.errors.redEdate}
                    </span>
                  ) : null}
                </div>

                <div className="col-12 col-md-4 mt-md-3 pt-1">
                  <button
                    className="btn btn-primary w-100 mt-md-4"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <button
                className="btn btn-primary w-100 d-none"
                type="button"
                id="emailQues"
                data-bs-toggle="modal"
                data-bs-target="#emailled_electronically"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="add_job_number_by_exist_number"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Job Number</h5>
            </div>
            <div className="modal-body">
              <div className="alert border-0 border-info border-start border-4 bg-light-info alert-dismissible fade show py-2">
                <div className="d-flex align-items-center">
                  <div className="fs-3 text-info">
                    <i className="bi bi-info-circle-fill"></i>
                  </div>
                  <div className="ms-3">
                    <div className="text-info text-info-bold">
                      {" "}
                      Please Select Redemption Start and End Date{" "}
                    </div>
                  </div>
                </div>
              </div>

              <form
                onSubmit={existDateAdd.handleSubmit}
                noValidate
                className="row g-3"
              >
                <div className="col-12 col-md-4 ">
                  <label className="form-label">Redemption Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="redSdate"
                    value={existDateAdd.values.redSdate || ""}
                    onChange={(e) => {
                      existDateAdd.handleChange(e);
                      changeExistRedemptionStartDate(e);
                    }}
                    onBlur={existDateAdd.handleBlur}
                  />
                  {existDateAdd.errors.redSdate &&
                  existDateAdd.touched.redSdate ? (
                    <span className="text-danger">
                      {existDateAdd.errors.redSdate}
                    </span>
                  ) : null}
                </div>
                <div className="col-12 col-md-4 ">
                  <label className="form-label">Redemption End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="redEdate"
                    value={existDateAdd.values.redEdate || ""}
                    onChange={existDateAdd.handleChange}
                    onBlur={existDateAdd.handleBlur}
                    disabled={true}
                  />
                  {existDateAdd.errors.redEdate &&
                  existDateAdd.touched.redEdate ? (
                    <span className="text-danger">
                      {existDateAdd.errors.redEdate}
                    </span>
                  ) : null}
                </div>

                <div className="col-12 col-md-4 mt-md-3 pt-1">
                  <button
                    className="btn btn-primary w-100 mt-md-4"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <button
                className="btn btn-primary w-100 d-none"
                type="button"
                id="emailQues"
                data-bs-toggle="modal"
                data-bs-target="#emailled_electronically"
              ></button>
              <button
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#add_job_number_by_edit_number"
                id="existredemptiondatemodelClose"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="add_job_number_by_edit_number"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Job Number</h5>
            </div>
            <div className="modal-body">
              <div className="alert  mb-0 border-0 border-info border-start border-4 bg-light-info alert-dismissible fade show py-2">
                <div className="d-flex align-items-center">
                  <div className="fs-3 text-info">
                    <i className="bi bi-info-circle-fill"></i>
                  </div>
                  <div className="ms-3">
                    <div className="text-info text-info-bold">
                      {" "}
                      You Want To Change Cards{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-success"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add_job_number_by_selecy_card_new"
                id="add_job_number_by_selecy_card_if_no_card"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  retainOldJob();
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="add_job_number_by_selecy_card_new"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Job Number</h5>
            </div>
            <div className="modal-body">
              <div className="alert border-0 border-info border-start border-4 bg-light-info alert-dismissible fade show py-2">
                <div className="d-flex align-items-center">
                  <div className="fs-3 text-info">
                    <i className="bi bi-info-circle-fill"></i>
                  </div>
                  <div className="ms-3">
                    <div className="text-info text-info-bold">
                      {" "}
                      Please select cards for this job{" "}
                    </div>
                  </div>
                </div>
              </div>

              <form onSubmit={cardSelectExist.handleSubmit} noValidate>
                <div className="row g-3">
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 1</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 1"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card1"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card1",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card1",
                            cardSelectExistInitialValues.card1
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card1", true)
                      }
                    />
                    {cardSelectExist.errors.card1 &&
                    cardSelectExist.touched.card1 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card1}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 2</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 2"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card2"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card2",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card2",
                            cardSelectExistInitialValues.card2
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card2", true)
                      }
                    />
                    {cardSelectExist.errors.card2 &&
                    cardSelectExist.touched.card2 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card2}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 3</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 3"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card3"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card3",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card3",
                            cardSelectExistInitialValues.card3
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card3", true)
                      }
                    />
                    {cardSelectExist.errors.card3 &&
                    cardSelectExist.touched.card3 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card3}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 4</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 4"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card4"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card4",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card4",
                            cardSelectExistInitialValues.card4
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card4", true)
                      }
                    />
                    {cardSelectExist.errors.card4 &&
                    cardSelectExist.touched.card4 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card4}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 5</label>

                    <Select
                      className="form-label"
                      placeholder="Select Card 5"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card5"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card5",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card5",
                            cardSelectExistInitialValues.card5
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card5", true)
                      }
                    />
                    {cardSelectExist.errors.card5 &&
                    cardSelectExist.touched.card5 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card5}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 6</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 6"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card6"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card6",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card6",
                            cardSelectExistInitialValues.card6
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card6", true)
                      }
                    />
                    {cardSelectExist.errors.card6 &&
                    cardSelectExist.touched.card6 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card6}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 7</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 7"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card7"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card7",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card7",
                            cardSelectExistInitialValues.card7
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card7", true)
                      }
                    />
                    {cardSelectExist.errors.card7 &&
                    cardSelectExist.touched.card7 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card7}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 8</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 8"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card8"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card8",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card8",
                            cardSelectExistInitialValues.card8
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card8", true)
                      }
                    />
                    {cardSelectExist.errors.card8 &&
                    cardSelectExist.touched.card8 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card8}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 9</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 9"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card9"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card9",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card9",
                            cardSelectExistInitialValues.card9
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card9", true)
                      }
                    />
                    {cardSelectExist.errors.card9 &&
                    cardSelectExist.touched.card9 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card9}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 10</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 10"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card10"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card10",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card10",
                            cardSelectExistInitialValues.card10
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card10", true)
                      }
                    />
                    {cardSelectExist.errors.card10 &&
                    cardSelectExist.touched.card10 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card10}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 11</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 11"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card11"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card11",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card11",
                            cardSelectExistInitialValues.card11
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card11", true)
                      }
                    />
                    {cardSelectExist.errors.card11 &&
                    cardSelectExist.touched.card11 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card11}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-12 col-md-4 ">
                    <label className="form-label">Card 12</label>
                    <Select
                      className="form-label"
                      placeholder="Select Card 12"
                      options={adminCards}
                      isSearchable={true}
                      isClearable={true}
                      name="card12"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          cardSelectExist.setFieldValue(
                            "card12",
                            selectedOption.value
                          );
                        } else {
                          cardSelectExist.setFieldValue(
                            "card12",
                            cardSelectExistInitialValues.card12
                          );
                        }
                      }}
                      onBlur={() =>
                        cardSelectExist.setFieldTouched("card12", true)
                      }
                    />
                    {cardSelectExist.errors.card12 &&
                    cardSelectExist.touched.card12 ? (
                      <span className="text-danger">
                        {cardSelectExist.errors.card12}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="row g-3">
                  <div className="col-12 col-md-4">
                    <button
                      className="btn btn-primary w-100 mt-4"
                      type="submit"
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-primary w-100 mt-4 d-none"
                      type="button"
                      id="add_job_number_by_selecy_card_new_close"
                      data-bs-dismiss="modal"
                    ></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToTop />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

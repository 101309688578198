import React from "react";
function HeaderLower() {
  return (
    <></>
    //     <div className="header_lower">
    //       {/* City Furniture and Elite Rewards are proud supporters of the American Cancer Society. */}
    //       {/* City Furniture and Elite Rewards are proud supporters of the American Heart Association's Life Is Why campaign. */}
    //       {/* CITY Furniture and Elite Rewards are proud to support our partners who are dedicated to creating diverse, equal, and inclusive communities and opportunities. */}
    //       {/* CITY Furniture and Elite Rewards are proud to support Junior Achievement to prepare our youth for their successful
    // futures. */}
    //     </div>
  );
}
export default HeaderLower;

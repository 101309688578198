import React from "react";
import { useState, useEffect } from "react";
import "./style.css";
// import Header from "./Header";
import Footer2 from "./Footer2";
// import UserformSurvey from "./mattress_questionnare";
import { Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import Recaptcha from "react-recaptcha";
import { Helmet } from "react-helmet";
import LogService from "../services/log.service";

// import axios from "axios";

function Index(props) {
  const TITLE = "winatripwithelite.com | Register";
  const navigate = useNavigate();
  const [website, setWebsite] = useState(window.location.href);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  const submitForm = (e) => {
    e.preventDefault();
    localStorage.setItem("return_page", "true");
    // props.history.push("/register/RegisterForm");
    navigate("/register/registration-form");
    // <--- The page you want to redirect your user to.
  };

  if (localStorage.getItem("return_page") === "true") {
    return <Navigate to="/register/registration-form" />;
  }

  localStorage.setItem("return_page", "false");
  localStorage.setItem("TestOurTech_thankyou", "false");

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <Header /> */}
        <div className="white-box">
          <ul className="points">
            <form
              method="post"
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submitForm}
            >
              <h2 className="register_lower" style={{ textAlign: "center" }}>
                {/* City Furniture and Elite Rewards are proud supporters of the
                American Cancer Society. */}

                {/* 4-4-2023 Changes */}
                {/* City Furniture and Elite Rewards are proud supporters of the
                American Heart Association's Life Is Why campaign. */}

                CITY Furniture and Elite Rewards are proud to support our
                partners who are dedicated to creating diverse, equal, and
                inclusive communities and opportunities.
              </h2>
              <div className="row">
                <div className="col-md-12" id="text">
                  {/* <p>We Give $25, You Get $25. Would you be interested in giving 5-10 minutes of your time to improve your sleep and help support our fight to end heart disease? We are offering you a $25 reward
         card to do a sleep survey and CITY Furniture will make a $25 donation to the American Heart Association. Just a few moments of your time can help improve your sleep and help save a life.</p> */}
                  <p className="register-p">
                    {" "}
                    {/* Would you be interested in giving 8-10 minutes of your time
                    to improve your sleep and be part of a movement to help end
                    breast cancer? You’ll get a $25 gift card when you complete
                    a mattress comfort test rest, and we’ll also give $25 to the
                    American Cancer Society. Just a few moments of your time can
                    help support breast cancer research, including access to
                    screenings and transportation to treatments. */}

                    {/* 4-4-2023 Changes */}
                    {/* Would you be interested in giving 8-10 minutes to improve
                    your sleep and also help support our fight to end heart
                    disease? We are offering a 25$ gift card to do a test rest
                    in our mattress gallery and also will matching that gift
                    card value as a donation to American Heart Association. Just
                    a few moments of your time can help improve your sleep and
                    help save a life! */}
                    
                    You Get $25, We Give $25. Would you be interested in giving
                    5-10 minutes of your time to improve your sleep and make an
                    impact in our community? We are offering you a $25 reward
                    card to do a sleep survey in our mattress gallery and CITY
                    Furniture will make a $25 donation to non-profit partners
                    who are promoting acceptance and inclusion, empowering small
                    businesses, and advancing social and economic equality. Just
                    a few moments of your time can help improve your sleep and
                    impact change in our community.
                  </p>
                </div>
              </div>
              <br />
              <input
                // onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit"
                value="CONTINUE"
                className="sub-btn button"
              ></input>
            </form>
          </ul>
        </div>
      </div>

      <Footer2 />
    </>
  );
}
export default Index;

import axios from "axios";
import {Buffer} from "buffer";
const API_URL = process.env.REACT_APP_API_Link;
const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;
const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");

const validateVoucher = (bodyFormData) => {
  return axios({
    method: "post",
    url: API_URL + "validateVoucher",
    data: bodyFormData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const RedemptionService = {
  validateVoucher,
};

export default RedemptionService;

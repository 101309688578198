import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { addState } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

export default function AddState() {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);


  const stateAdd = async (values) => {
    setLoading(true);

    try {
    
      const responce = await AdminListService.addStates(values);

      if (responce.status === 200) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Added Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          navigate("/state_manage");
        }, 1500);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name: "",
        code: "",
      },
      validationSchema: addState,
      onSubmit: (values) => {
        stateAdd(values);
      },
    });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />

          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Add State</h5>
            </div>

            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit} noValidate>
                <div className="col-12 col-md-3">
                  <label className="form-label">State Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State Name"
                    name="name"
                    value={values.name.toUpperCase() || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && touched.name ? (
                    <span className="text-danger">{errors.name}</span>
                  ) : null}
                </div>

                <div className="col-12 col-md-3">
                  <label className="form-label">State code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State code"
                    name="code"
                    value={values.code.toUpperCase() || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.code && touched.code ? (
                    <span className="text-danger">{errors.code}</span>
                  ) : null}
                </div>

                <div className="col-12 col-md-3">
                  <label className="form-label invisible">
                    Save State Record
                  </label>

                  <button className="btn btn-primary w-100">
                    Save State Record
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

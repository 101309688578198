import axios from "axios";
import {Buffer} from "buffer";
const API_URL = process.env.REACT_APP_API_Link;
// const API_URL = "http://localhost/SYC-API/api/";

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;
const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");

const createLog = (website) => {
  var bodyFormData = new FormData();
  bodyFormData.append("url", website);

  return axios({
    method: "post",
    url: API_URL + "createLog",
    data: bodyFormData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const createFormLog = (website, type, formData) => {
  const postData = JSON.stringify({
    url: website,
    reqType: type,
    data: formData,
  });

  return axios({
    method: "post",
    url: API_URL + "createFormLog",
    data: postData,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const apiResLog = (website, formData) => {
  const postData = JSON.stringify({
    url: website,
    reqType: "GET",
    data: formData,
  });

  return axios({
    method: "post",
    url: API_URL + "apiResLog",
    data: postData,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const LogService = {
  createLog,
  createFormLog,
  apiResLog,
};

export default LogService;

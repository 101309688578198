import React from "react";

function LoadingBox(props) {
  let name = props.name || "center";
  return (
    <div className={`d-flex justify-content-${name}`}>
      <div className="spinner-border text-bg-light" role="status"></div>
    </div>
  );
}

export default LoadingBox;

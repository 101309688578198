import React from "react";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

export default function ExportSendMail() {
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Export Contact's</h5>
            </div>

            <div className="card-body">
              <form action="" className="row g-3">
                <div className="col-12 col-md-5">
                  <label htmlFor="MailTo" className="form-label">
                    MailTo
                  </label>
                  <input className="form-control" id="MailTo" type="text" />
                  <span className="text-danger">MailTo is Required</span>
                </div>

                <div className="col-12 col-md-2">
                  <label
                    htmlFor="formFileMultiple"
                    className="form-label invisible "
                  >
                    MailTo
                  </label>
                  <input
                    type="submit"
                    className="btn btn-primary w-100"
                    value="Send Mail"
                  />
                </div>
              </form>
            </div>
          </div>{" "}
        </div>
      </main>
      <Footer />
    </div>
  );
}

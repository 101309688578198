import { useFormik } from "formik";
import React, { useState } from "react";
import { searchCerti } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

export default function CertRecords() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [tableData, settableData] = useState([]);

  const searchCertificate = async (values) => {
    setLoading(true);
    try {
      const responce = await AdminListService.certiSearch(values);

      if (responce.status === 200) {
        const res = responce.data.data;
        const results = [];

        results.push({
          ADDRESS: res.ADDRESS && res.ADDRESS !== "" ? res.ADDRESS : "N/A",
          CITY: res.CITY && res.CITY !== "" ? res.CITY : "N/A",
          EmailAddress:
            res.EmailAddress && res.EmailAddress !== ""
              ? res.EmailAddress
              : "N/A",
          FIRST_NAME:
            res.FIRST_NAME && res.FIRST_NAME !== "" ? res.FIRST_NAME : "N/A",
          LAST_NAME:
            res.LAST_NAME && res.LAST_NAME !== "" ? res.LAST_NAME : "N/A",
          PHONE_NUMBER:
            res.PHONE_NUMBER && res.PHONE_NUMBER !== ""
              ? res.PHONE_NUMBER
              : "N/A",
          STATE: res.STATE && res.STATE !== "" ? res.STATE : "N/A",
          ZIP: res.ZIP && res.ZIP !== "" ? res.ZIP : "N/A",
        });

        settableData([...results]);
        setShow(true);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      settableData([]);
      setShow(true);
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        certiNo: "",
      },
      validationSchema: searchCerti,
      onSubmit: (values) => {
        searchCertificate(values);
      },
    });

  const Table = ({ data }) => {
    return (
      <div className="srch_certi-result">
        <div className="table-responsive mt-4">
          {data.length ? (
            data.map((el, index) => (
              <fieldset key={index}>
                <legend>
                  <h3>New Information</h3>
                </legend>
                <table width="100%" border="0" cellPadding="10">
                  <tbody>
                    <tr>
                      <td>
                        <strong>First Name:</strong>
                      </td>
                      <td>{el.FIRST_NAME}</td>
                      <td>
                        <strong>Last Name:</strong>
                      </td>
                      <td>{el.LAST_NAME}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Address:</strong>
                      </td>
                      <td>{el.ADDRESS}</td>
                      <td>
                        <strong>City:</strong>
                      </td>
                      <td>{el.CITY}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>State:</strong>
                      </td>
                      <td>{el.STATE}</td>
                      <td>
                        <strong>Zip Code:</strong>
                      </td>
                      <td>{el.ZIP}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Phone No:</strong>
                      </td>
                      <td>{el.PHONE_NUMBER}</td>
                      <td>
                        <strong>E-mail:</strong>
                      </td>
                      <td>{el.EmailAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            ))
          ) : (
            <fieldset>
              <legend>
                <h3>No data found.</h3>
              </legend>
            </fieldset>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Search Certificate Records</h5>
            </div>

            <div className="card-body">
              <form className="row g-3" onSubmit={handleSubmit} noValidate>
                <div className="col-12 col-md-6">
                  <label className="form-label">Certificate Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Certificate Number"
                    name="certiNo"
                    value={values.certiNo || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.certiNo && touched.certiNo ? (
                    <span className="text-danger">{errors.certiNo}</span>
                  ) : null}
                </div>

                <div className="col-12 col-md-3">
                  <label className="form-label invisible">
                    Save State Record
                  </label>

                  <button className="btn btn-primary w-100" type="submit">
                    Search Certificate Record
                  </button>
                </div>
              </form>
              {show ? <Table data={tableData} /> : null}
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

import secureLocalStorage from "react-secure-storage";

// secureLocalStorage.setItem("acessTokens", JSON.stringify(acessTokens));

let retrievedObject = JSON.parse(secureLocalStorage.getItem("acessTokens"));

// set data for customer
const customerAccessToken = retrievedObject?.customerAccessToken;
const customer_id = retrievedObject?.customer_id;
const customerSiteId = retrievedObject?.customerSiteId;
// set data for accountant
const accountantAccessToken = retrievedObject?.accountantAccessToken;
const accountant_id = retrievedObject?.accountant_id;
// set data for osr
const osrAccessToken = retrievedObject?.osrAccessToken;
const osr_id = retrievedObject?.osr_id;

// set data for pavline
const pavlinaAccessToken = retrievedObject?.pavlinaAccessToken;
const pavlina_id = retrievedObject?.pavlina_id;

// set data for admin
const adminAccessToken = retrievedObject?.adminAccessToken;
const admin_id = retrievedObject?.admin_id;

if (!retrievedObject) {
  retrievedObject = {};
}

const initialState = {
  customerAccessToken: customerAccessToken ? customerAccessToken : null,
  customer_id: customer_id ? customer_id : null,
  customerSiteId: customerSiteId ? customerSiteId : null,
  accountantAccessToken: accountantAccessToken ? accountantAccessToken : null,
  accountant_id: accountant_id ? accountant_id : null,
  osrAccessToken: osrAccessToken ? osrAccessToken : null,
  osr_id: osr_id ? osr_id : null,
  pavlinaAccessToken: pavlinaAccessToken ? pavlinaAccessToken : null,
  pavlina_id: pavlina_id ? pavlina_id : null,
  adminAccessToken: adminAccessToken ? adminAccessToken : null,
  admin_id: admin_id ? admin_id : null,
};

const reducer = (state = initialState, action) => {
  if (action.type === "customerLogin") {
    retrievedObject.customerAccessToken = action?.payload?.customerAccessToken;
    retrievedObject.customer_id = action?.payload?.customer_id;
    retrievedObject.customerSiteId = action?.payload?.customerSiteId;
    secureLocalStorage.setItem("acessTokens", JSON.stringify(retrievedObject));

    return {
      ...state,
      customerAccessToken: action.payload.customerAccessToken,
      customer_id: action.payload.customer_id,
      customerSiteId: action.payload.customerSiteId,
    };
  } else if (action.type === "customerLogOut") {
    delete retrievedObject?.customer_id;
    delete retrievedObject?.customerAccessToken;
    delete retrievedObject?.customerSiteId;

    if (Object.keys(retrievedObject).length !== 0) {
      secureLocalStorage.setItem(
        "acessTokens",
        JSON.stringify(retrievedObject)
      );
    } else {
      secureLocalStorage.removeItem("acessTokens");
    }
    return {
      ...state,
      customerAccessToken: null,
      customer_id: null,
    };
  } else if (action.type === "accountantLogin") {
    retrievedObject.accountantAccessToken =
      action.payload.accountantAccessToken;
    retrievedObject.accountant_id = action.payload.accountant_id;
    secureLocalStorage.setItem("acessTokens", JSON.stringify(retrievedObject));

    return {
      ...state,
      accountantAccessToken: action.payload.accountantAccessToken,
      accountant_id: action.payload.accountant_id,
    };
  } else if (action.type === "accountantLogOut") {
    delete retrievedObject?.accountant_id;
    delete retrievedObject?.accountantAccessToken;
    if (Object.keys(retrievedObject).length !== 0) {
      secureLocalStorage.setItem(
        "acessTokens",
        JSON.stringify(retrievedObject)
      );
    } else {
      secureLocalStorage.removeItem("acessTokens");
    }

    return {
      ...state,
      accountantAccessToken: null,
      accountant_id: null,
    };
  } else if (action.type === "osrLogin") {
    retrievedObject.osrAccessToken = action.payload.osrAccessToken;
    retrievedObject.osr_id = action.payload.osr_id;
    secureLocalStorage.setItem("acessTokens", JSON.stringify(retrievedObject));

    return {
      ...state,
      osrAccessToken: action.payload.osrAccessToken,
      osr_id: action.payload.osr_id,
    };
  } else if (action.type === "osrLogOut") {
    delete retrievedObject?.osr_id;
    delete retrievedObject?.osrAccessToken;
    if (Object.keys(retrievedObject).length !== 0) {
      secureLocalStorage.setItem(
        "acessTokens",
        JSON.stringify(retrievedObject)
      );
    } else {
      secureLocalStorage.removeItem("acessTokens");
    }

    return {
      ...state,
      osrAccessToken: null,
      osr_id: null,
    };
  } else if (action.type === "pavlinaLogin") {
    retrievedObject.pavlinaAccessToken = action.payload.pavlinaAccessToken;
    retrievedObject.pavlina_id = action.payload.pavlina_id;
    secureLocalStorage.setItem("acessTokens", JSON.stringify(retrievedObject));

    return {
      ...state,
      pavlinaAccessToken: action.payload.pavlinaAccessToken,
      pavlina_id: action.payload.pavlina_id,
    };
  } else if (action.type === "pavlinaLogOut") {
    delete retrievedObject?.pavlina_id;
    delete retrievedObject?.pavlinaAccessToken;
    if (Object.keys(retrievedObject).length !== 0) {
      secureLocalStorage.setItem(
        "acessTokens",
        JSON.stringify(retrievedObject)
      );
    } else {
      secureLocalStorage.removeItem("acessTokens");
    }

    return {
      ...state,
      pavlinaAccessToken: null,
      pavlina_id: null,
    };
  } else if (action.type === "adminLogin") {
    retrievedObject.adminAccessToken = action.payload.adminAccessToken;
    retrievedObject.admin_id = action.payload.admin_id;
    secureLocalStorage.setItem("acessTokens", JSON.stringify(retrievedObject));

    return {
      ...state,
      adminAccessToken: action.payload.adminAccessToken,
      admin_id: action.payload.admin_id,
    };
  } else if (action.type === "adminLogOut") {
    delete retrievedObject?.admin_id;
    delete retrievedObject?.adminAccessToken;
    if (Object.keys(retrievedObject).length !== 0) {
      secureLocalStorage.setItem(
        "acessTokens",
        JSON.stringify(retrievedObject)
      );
    } else {
      secureLocalStorage.removeItem("acessTokens");
    }

    return {
      ...state,
      adminAccessToken: null,
      admin_id: null,
    };
  } else {
    return state;
  }
};
export default reducer;

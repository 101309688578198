import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { updateCertiPyp } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import ToTop from "../includes/ToTop";
import $ from "jquery";

export default function EditLockup() {
  const state = useSelector((state) => state.stateVals);

  const { admin_id } = state;
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [StatesList, setStatesList] = useState([]);
  const [adminCards, setAdminCards] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    if (admin_id) {
      const getStatesNames = async () => {
        try {
          const responce = await AdminListService.getStates();

          const res = responce.data.data;
          const results = [];
          res.map((value) => {
            return results.push({
              id: value.code,
              name: value.state_name,
            });
          });
          setStatesList([...results]);
        } catch (err) {
          console.log(err);
          setStatesList([]);
        }
      };
      getStatesNames();

      const getCardsAdmin = async () => {
        try {
          const responce = await AdminListService.getAdminCards();

          const res = responce.data.data;
          const results = [];
          res.map((value) => {
            return results.push({
              id: value.CardId,
              name: value.CardName,
            });
          });
          setAdminCards([...results]);
        } catch (err) {
          console.log(err);
          setAdminCards([]);
        }
      };

      getCardsAdmin();

      const getLockUpById = async () => {
        setLoading(true);
        try {
          const response = await AdminListService.lockupRecordById(id);

          if (response.status === 200) {
            setLoading(false);
            await certiSearch.setValues({
              fName: response.data.data.FIRST_NAME,
              lName: response.data.data.LAST_NAME,
              address: response.data.data.ADDRESS,
              city: response.data.data.CITY,
              state: response.data.data.STATE,
              zipCode: response.data.data.ZIP,
              email: response.data.data.EmailAddress,
              phone: response.data.data.PHONE_NUMBER,
              certiNo: response.data.data.CERT_NUMBER_1,
              custName: response.data.data.CUSTOMER,

              denom: response.data.data.DENOMINATION,

              redemSt: response.data.data.REDEMPTION_BEINS,
              redemEd: response.data.data.REDEMPTION_ENDS,
              complnId: response.data.data.UNIQUE_ID,

              jobNo: response.data.data.JOB_NUMBER,
              cardType: response.data.data.CardType,
              certiStatus: response.data.data.CERT_STATUS,
              statusDate: response.data.data.status_date,
              recMeth: response.data.data.RECEIVED_METHOD,
              CerUpdateOn: response.data.data.CerUpdateOn,
              CerAddOn: response.data.data.CerAddOn,
              CerUpdateBy: response.data.data.CerUpdateBy,
              notes: response.data.data.FILE_NOTES,
            });
          }
          changeRecieveMethod(response.data.data.CERT_STATUS);
        } catch (err) {
          console.log(err);
          setLoading(false);
          if (err?.response?.data?.message) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: err?.response?.data?.message,
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: "Some thing went wrong!",
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          }
        }
      };
      getLockUpById();
    }

    // eslint-disable-next-line
  }, []);

  const changeRecieveMethod = (value) => {
    //alert(value);

    if (value === "Active") {
      $("#ceckstatus").css("background", "green");
    }
    if (value === "Voucher Mailed") {
      $("#ceckstatus").css("background", "purple");
    }
    if (value === "Voucher Recieved") {
      $("#ceckstatus").css("background", "SandyBrown");
    }
    if (value === "Card Ordered") {
      $("#ceckstatus").css("background", "pink");
    }
    if (value === "Card Mailed") {
      $("#ceckstatus").css("background", "SkyBlue");
    }
    if (value === "Expired") {
      $("#ceckstatus").css("background", "Salmon");
    }
    if (value === "Void") {
      $("#ceckstatus").css("background", "red");
    }
  };

  const updateCertById = async (values) => {
    setLoading(true);

    try {
      const responce = await AdminListService.lockupUpdateById(values, id);

      if (responce.status === 200) {
        setLoading(false);

        Swal.fire({
          icon: "success",
          title: "Updated Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => {
          navigate("/lookup_manage");
        }, 1500);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Oops...",
        iconColor: "#e04f5d",
        confirmButtonColor: "#e72e2e",
        text: "Something went wrong!",
      });
    }
  };

  const certiSearch = useFormik({
    initialValues: {
      certiNo: "",
      custName: "",
      denom: "",
      redemSt: "",
      redemEd: "",
      complnId: "",
      jobNo: "",
      fName: "",
      lName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      email: "",
      cardType: "",
      certiStatus: "",
      statusDate: "",
      recMeth: "",
      notes: "",
      newNotes: "",
      CerAddOn: "",
      CerUpdateOn: "",
      CerUpdateBy: "",
    },
    validationSchema: updateCertiPyp,
    onSubmit: (values, action) => {
      updateCertById(values, action);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="card">
          <div className="card-header py-3">
            <h5 className="mb-0 font-22">
              <div className="alert alert-dark border-0 bg-dark alert-dismissible fade show">
                <div className="text-white">
                  When no certificate number is found in step one always start
                  search by selecting other fields
                </div>
              </div>
            </h5>
          </div>

          <div className="card-body">
            <form
              className="row g-3"
              onSubmit={certiSearch.handleSubmit}
              noValidate
            >
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Certificate Number 1</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Certificate Number 1"
                  name="certiNo"
                  value={certiSearch.values.certiNo || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                  disabled={true}
                />
                {certiSearch.errors.certiNo && certiSearch.touched.certiNo ? (
                  <span className="text-danger">
                    {certiSearch.errors.certiNo}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Customer Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Customer Name"
                  name="custName"
                  value={certiSearch.values.custName || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.custName && certiSearch.touched.custName ? (
                  <span className="text-danger">
                    {certiSearch.errors.custName}
                  </span>
                ) : null}
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Job Number</label>
                <input
                  type="text"
                  className="form-control "
                  aria-label="Disabled input example"
                  placeholder="Job Number"
                  name="jobNo"
                  value={certiSearch.values.jobNo || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                  disabled={true}
                />
                {certiSearch.errors.jobNo && certiSearch.touched.jobNo ? (
                  <span className="text-danger">
                    {certiSearch.errors.jobNo}
                  </span>
                ) : null}
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Denomination </label>
                <input
                  type="text"
                  className="form-control disabled"
                  placeholder="Denomination"
                  name="denom"
                  value={certiSearch.values.denom || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.denom && certiSearch.touched.denom ? (
                  <span className="text-danger">
                    {certiSearch.errors.denom}
                  </span>
                ) : null}
              </div>

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Redemption Period Begin</label>
                <input
                  type="date"
                  className="form-control "
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Redemption Period Begin"
                  name="redemSt"
                  value={certiSearch.values.redemSt || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.redemSt && certiSearch.touched.redemSt ? (
                  <span className="text-danger">
                    {certiSearch.errors.redemSt}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Redemption Period End</label>
                <input
                  type="date"
                  className="form-control "
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Redemption Period End"
                  name="redemEd"
                  value={certiSearch.values.redemEd || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.redemEd && certiSearch.touched.redemEd ? (
                  <span className="text-danger">
                    {certiSearch.errors.redemEd}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Compain ID No.</label>
                <input
                  type="number"
                  className="form-control "
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Compain ID No."
                  name="complnId"
                  value={certiSearch.values.complnId || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.complnId && certiSearch.touched.complnId ? (
                  <span className="text-danger">
                    {certiSearch.errors.complnId}
                  </span>
                ) : null}
              </div>
              <hr className="mb-0" />

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  name="fName"
                  value={certiSearch.values.fName || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.fName && certiSearch.touched.fName ? (
                  <span className="text-danger">
                    {certiSearch.errors.fName}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  name="lName"
                  value={certiSearch.values.lName || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.lName && certiSearch.touched.lName ? (
                  <span className="text-danger">
                    {certiSearch.errors.lName}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  name="address"
                  value={certiSearch.values.address || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.address && certiSearch.touched.address ? (
                  <span className="text-danger">
                    {certiSearch.errors.address}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  name="city"
                  value={certiSearch.values.city || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.city && certiSearch.touched.city ? (
                  <span className="text-danger">{certiSearch.errors.city}</span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">State</label>
                <select
                  className="form-select dark-border"
                  name="state"
                  value={certiSearch.values.state || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                >
                  <option value="">Select State</option>
                  {StatesList.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>

                {certiSearch.errors.state && certiSearch.touched.state ? (
                  <span className="text-danger">
                    {certiSearch.errors.state}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Zip</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Zip"
                  name="zipCode"
                  value={certiSearch.values.zipCode || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.zipCode && certiSearch.touched.zipCode ? (
                  <span className="text-danger">
                    {certiSearch.errors.zipCode}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phone"
                  value={certiSearch.values.phone || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.phone && certiSearch.touched.phone ? (
                  <span className="text-danger">
                    {certiSearch.errors.phone}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  name="email"
                  value={certiSearch.values.email || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.email && certiSearch.touched.email ? (
                  <span className="text-danger">
                    {certiSearch.errors.email}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Card Type</label>
                <select
                  className="form-select tick-border"
                  name="cardType"
                  value={certiSearch.values.cardType || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                >
                  <option value="">Select Card Type</option>
                  {adminCards.map((el, index) => {
                    return (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>

                {certiSearch.errors.cardType && certiSearch.touched.cardType ? (
                  <span className="text-danger">
                    {certiSearch.errors.cardType}
                  </span>
                ) : null}
              </div>

              <hr className="mb-0" />

              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Certificate Status</label>
                <select
                  id="ceckstatus"
                  className="dark-border"
                  onChange={(e) => {
                    certiSearch.handleChange(e);
                    changeRecieveMethod(e.target.value);
                  }}
                  name="certiStatus"
                  value={certiSearch.values.certiStatus || ""}
                  onBlur={certiSearch.handleBlur}
                >
                  <option value="" style={{ backgroundColor: "gray" }}>
                    STATUS
                  </option>
                  <option value="Active" style={{ backgroundColor: "green" }}>
                    Active
                  </option>
                  <option
                    value="Voucher Mailed"
                    style={{ backgroundColor: "purple" }}
                  >
                    Voucher Mailed
                  </option>
                  <option
                    value="Voucher Recieved"
                    style={{ backgroundColor: "SandyBrown" }}
                  >
                    Voucher Recieved
                  </option>
                  <option
                    value="Card Ordered"
                    style={{ backgroundColor: "pink" }}
                  >
                    Card Ordered
                  </option>
                  <option
                    value="Card Mailed"
                    style={{ backgroundColor: "SkyBlue" }}
                  >
                    Card Mailed
                  </option>
                  <option value="Expired" style={{ backgroundColor: "Salmon" }}>
                    Expired
                  </option>
                  <option value="Void" style={{ backgroundColor: "red" }}>
                    Void
                  </option>
                </select>

                {certiSearch.errors.certiStatus &&
                certiSearch.touched.certiStatus ? (
                  <span className="text-danger">
                    {certiSearch.errors.certiStatus}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Status Date</label>
                <input
                  type="date"
                  className="form-control "
                  aria-label="Disabled input example"
                  disabled
                  placeholder="Status Date"
                  name="statusDate"
                  value={certiSearch.values.statusDate || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                />
                {certiSearch.errors.statusDate &&
                certiSearch.touched.statusDate ? (
                  <span className="text-danger">
                    {certiSearch.errors.statusDate}
                  </span>
                ) : null}
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <label className="form-label">Receive Method</label>
                <select
                  className="form-select dark-border"
                  name="recMeth"
                  value={certiSearch.values.recMeth || ""}
                  onChange={certiSearch.handleChange}
                  onBlur={certiSearch.handleBlur}
                >
                  <option value="">Select Receive Method</option>
                  <option value="Mail">Mail</option>
                  <option value="Phone">Phone</option>
                  <option value="Web">Web</option>
                </select>
                {certiSearch.errors.recMeth && certiSearch.touched.recMeth ? (
                  <span className="text-danger">
                    {certiSearch.errors.recMeth}
                  </span>
                ) : null}
              </div>

              <div className="col-12 add-space-btns">
                <button type="submit" className="btn btn-primary  mt-2">
                  Save Record
                </button>
                <button
                  type="button"
                  onClick={certiSearch.resetForm}
                  className="btn btn-primary mt-2"
                >
                  Reset Form
                </button>
                <button
                  type="button"
                  className="btn btn-primary mt-2"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>

                <div className="clearfix"></div>
              </div>
            </form>
          </div>
        </div>
      </main>

      <ToTop />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}

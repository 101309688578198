import React from "react";
import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

export default function OldFile() {
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                You Can Import XLSX File For Users
              </h5>
            </div>

            <div className="card-body">
              <form action="" className="row g-3">
                <div className="col-12 col-md-5">
                  <label htmlFor="selectfile" className="form-label">
                    Please Select File
                  </label>
                  <input className="form-control" id="selectfile" type="file" />
                  <span className="text-danger">Please Select File</span>
                </div>

                <div className="col-12 col-md-2">
                  <label
                    htmlFor="formFileMultiple"
                    className="form-label invisible "
                  >
                    Select File
                  </label>
                  <input
                    type="submit"
                    className="btn btn-primary w-100"
                    value="Submit"
                  />
                </div>
                <div className="col-12 col-md-12">
                  <div className="form-check form-switch">
                    <label className="form-check-label" htmlFor="sendMail">
                      Send Email:
                    </label>
                    <input
                      className="form-check-input me-3"
                      type="checkbox"
                      defaultChecked={true}
                      id="sendMail"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
